<template>
	<div>
		<CustomTable
			id_table="avenant_articles"
			v-if="show_table"
			:items="conditions_formatted"
			:busy="table_busy"
			primaryKey="avenantarticles_id"
			:hide_if_empty="true"
			:externSlotColumns="extern_slot_columns"
			:checkboxes="false"
			:display_action_button="display_action_button"
			:hasExportOption="false"
			:hasPaginationSelect="false"
			:hasConfTableAccess="false"
			:filtre_general="false"
		>
			<template v-slot:custom-slot-cell(avenantarticles_ht_initial)="{ data }">
				<PriceInput v-model="data.avenantarticles_ht_initial" :disabled="data.disabled" @change="setHtInitialConditions(data.avenantarticles_ht_initial, data.avenantarticles_id)" />
			</template>
			<template v-slot:custom-slot-cell(avenantarticles_quotepart)="{ data }">
				<b-input type="text" class="form-control" v-model="data.avenantarticles_quotepart" :disabled="data.disabled" @change="setQuotePartCondition(data.avenantarticles_quotepart, data.avenantarticles_id)"/>
			</template>
			<template v-slot:custom-slot-cell(avenantarticles_htunit_before_discount)="{ data }">
				<PriceInput v-model="data.avenantarticles_htunit_before_discount" :disabled="true" @change="setHtConditions(data.avenantarticles_htunit_before_discount, data.avenantarticles_id)" />
			</template>
			<template v-slot:custom-slot-cell(avenantarticles_qte)="{ data }">
				<b-input type="text" class="form-control" v-model="data.avenantarticles_qte" :disabled="data.disabled" @change="setQteCondition(data.avenantarticles_qte, data.avenantarticles_id)" />
			</template>
			<template v-slot:custom-slot-cell(avenantarticles_discount_value)="{ data }">
				<div class="d-flex">
					<b-input v-if="data.avenantarticles_discount_type.invoicediscounttype_type != 'none'" type="text" class="form-control" v-model="data.avenantarticles_discount_value" :disabled="data.disabled" @change="setDiscountValueCondition(data.avenantarticles_discount_value, data.avenantarticles_id)"/>
					<e-select
						v-model="data.avenantarticles_discount_type"
						id="discount_type"
						track-by="invoicediscounttype_id"
						label="invoicediscounttype_label"
						:selectedLabel="selectedLabel"
						:options="discount_type_formatted"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						@input="setDiscountTypeCondition(data.avenantarticles_discount_type, data.avenantarticles_id)"
						@open="onTableSelectOpen"
						@close="onTableSelectClose"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</template>
			<template v-slot:[`custom-slot-cell(tiers.tiers_rs)`]="{ data }">
				<e-select
					v-model="data.tiers"
					track-by="tiers_id"
					label="tiers_rs"
					:options="tiers_tab"
					:disabled="data.disabled"
					:allow-empty="false"
					:show-labels="false"
					@input="setTiersConditions(data.tiers, data.avenantarticles_id)"
					@open="onTableSelectOpen"
					@close="onTableSelectClose"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
					<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
				</e-select>
			</template>
			<template v-slot:[`custom-slot-cell(author.tiers_rs)`]="{ data }">
				<e-select
					v-model="data.author"
					track-by="tiers_id"
					label="tiers_rs"
					:options="entities"
					:disabled="data.disabled"
					:allow-empty="false"
					:show-labels="false"
					@input="setAuthorConditions(data.author, data.avenantarticles_id)"
					@open="onTableSelectOpen"
					@close="onTableSelectClose"
				></e-select>
			</template>
			<template v-slot:[`custom-slot-cell(articles_label)`]="{ data }">
				{{data.articles_label}} / {{data.author.tiers_rs}}
			</template>

			<template v-slot:custom-slot-cell(avenantarticles_invoiceable_text)="{ data }">

				<div class="input-group mb-2">
					<input readonly class="form-control-plaintext" v-model="data.avenantarticles_invoiceable_text" :disabled="data.disabled">
					<div class="input-group-append">
						<div class="input-group-text no-color-no-background">
							<span v-show="data.button_type == 'credit'" v-b-tooltip.hover.left :title="$t('monte.faire_avoir')" @click='create_credit(data.avenantarticles_id)'><font-awesome-icon :icon="['fal', 'trash']" class='ml-1'/></span>
							<span v-show="data.button_type == 'delete'" v-b-tooltip.hover.left :title="$t('monte.supprimer_ligne')" @click='delete_ligne(data.avenantarticles_id)'><font-awesome-icon :icon="['fal', 'trash']" class='ml-1'/></span>
						</div>
					</div>
				</div>
			</template>
		</CustomTable>


		<b-modal ref="modalLigneVierge" no-close-on-backdrop hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.ajout_article_avenant") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<ModalAddArticle ref="ligne" :processing.sync="processing" :ready.sync="ready" :tiers_id="tiers.tiers_id" :avenant_id="avenant.avenant_id" :contract_id="contract_id"></ModalAddArticle>

				<div class="col-8 m-auto">
					<b-button v-if="ready" block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
					<b-button v-else block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalCalculTarif" hide-footer size="xl">
			<template v-slot:modal-title>
				{{ $t("monte.recaluler_article") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<div class="row" v-for="(articles, key) in recalculated_articles" :key="key">
					<div class="col-12">
						<label for="">{{ articles.articles_label }}</label>
					</div>
					<div class="col-6">
						<label>{{ $t('invoice.invoicedetails_htunit') }}</label>
					</div>
					<div class="col-3">
						<label>{{ $t('acte.quantite') }}</label>
					</div>
					<div class="col-3">
						<label>{{ $t('acte.ht') }}</label>
					</div>

					<div class="col-6">
						<div class="form-group" >
							<PriceInput v-model="articles.articles_htunit" @change="setHtArticle(articles.articles_htunit, articles.contractconfigconditionsarticles_id)"/>
						</div>
					</div>
					<div class="col-3">
						<b-input type="text" class="form-control" v-model="articles.articles_qte" @change="setQteArticle(articles.articles_qte, articles.contractconfigconditionsarticles_id)"/>
					</div>

					<div class="col-3">
						<b-input v-if="show_ht" type="text" class="form-control" v-model="articles.articles_ht" :disabled="true"/>
					</div>
					
				</div>

				<div class="col-8 m-auto">
					<b-button v-if="ready" block pill variant="primary" @click="reloadCondition(true, recalculated_articles)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
				</div>
			</div>
		</b-modal>



	</div>
</template>


<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import HorseMixin from "@/mixins/Horse.js"
	import InvoiceMixin from "@/mixins/Invoice.js"
	import TableSelectMixin from "@/mixins/TableSelect.js"
	import Common from '@/assets/js/common'
	import Queue from '@/assets/js/utils/queue'
	import _cloneDeep from "lodash/cloneDeep"


	export default {
		name: 'TableAvenantArticle',
		mixins: [Navigation, Tools, ContractMixin, TiersMixin, HorseMixin, InvoiceMixin, TableSelectMixin],
		props: {
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			contract_id:{
				type: Number,
				default: () => ( 0 )
			},
			typemonte:{
				type: Object,
				default: () => ( null )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			tiers_tab: {
				type: Array,
				default: () => ( [] )
			},
			tiers: {
				type: Object,
				default: () => ( null )
			},
			avenant: {
				type: Object,
				default: () => ( {} )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
			launch_reload_condition:{
				type: Boolean,
				default: () => ( false )
			},
			change_emetteur: {
				type: Object,
				default: () => ( {} )
			},
			created_horse_id: {
				type: Number,
				default: () => ( 0 )
			},
			display_action_button: {
				type: Boolean,
				default: () => ( false )
			},
			free: {
				type: [Number, Boolean],
				default: () => ( 0 )
			},
			is_ready: {
				type: [Boolean],
				default: () => (false)
			},
			articles: {
				type: [Array, String],
				default: () => ( [] )
			}
		},
		data () {
			return {
				table_busy: false,
				queue: new Queue(),
				conditions_local: [],
				recalculated_articles: [],
				entities: [],
				horse_id: null,
				processing: false,
				show_ht: true,
				ready: false,
				modal_loaded: false,
				reload_articles: false,
				show_table: true,
				events_tab: {
					'TableAction::goToAddArticlesToAvenant': this.addArticlesToAvenant,
					'TableAction::goToRecalculArticlesAvenant': this.forceReloadCondition,
					'TableAvenantArticle::ReloadArticle': this.reloadArticleEvent
				},

				extern_slot_columns: [
					'avenantarticles_ht_initial',
					'avenantarticles_quotepart',
					'avenantarticles_qte',
					'avenantarticles_discount_value',
					'tiers.tiers_rs',
					// 'author.tiers_rs',
					'articles_label',
					'avenantarticles_invoiceable_text',
				],
				selectedLabel: this.getTrad("global.selected_label"),
				discount_type: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.entities = await this.loadTiersEntity()
				this.discount_type = await this.loadDiscountType()

				if(this.launch_reload_condition){
					this.reloadCondition()
				}
				else {
					// update le isTableAvenantArticleReady de ContractForm pour permettre de save le contrat
					this.$emit('update:is_ready', true)
				}
			},

			setTiersConditions(tiers, avenantarticles_id){
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.tiers = tiers
			},
			setAuthorConditions(author, avenantarticles_id){
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.author = author
			},
			async setHtInitialConditions(ht_initial, avenantarticles_id) {
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_ht_initial = ht_initial
				this.reloadConditionLine(avenantarticles_id)
			},
			async setQuotePartCondition(part, avenantarticles_id) {
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_quotepart = part
				this.reloadConditionLine(avenantarticles_id)
			},
			async setHtConditions(ht, avenantarticles_id){
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_htunit_before_discount = ht
				this.reloadConditionLine(avenantarticles_id)
			},
			async setQteCondition(qte, avenantarticles_id) {
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_qte = parseFloat(qte)
				this.reloadConditionLine(avenantarticles_id)
			},
			async setDiscountTypeCondition(discount_type, avenantarticles_id) {
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_discount_type = discount_type
				this.reloadConditionLine(avenantarticles_id)
			},
			async setDiscountValueCondition(discount_value, avenantarticles_id) {
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)
				elem_to_edit.avenantarticles_discount_value = discount_value
				this.reloadConditionLine(avenantarticles_id)
			},

			async reloadConditionLine(avenantarticles_id) {
				this.show_table = false
				const elem_to_edit = this.conditions_local.find(elem => avenantarticles_id == elem.avenantarticles_id)

				// on recalcule d'abord le prix ht unitaire après remise
				const discount_type = elem_to_edit.avenantarticles_discount_type
				const discount_value = elem_to_edit.avenantarticles_discount_value.toString().replace(',', '.')
				const qte = elem_to_edit.avenantarticles_qte

				const part = elem_to_edit.avenantarticles_quotepart.toString().replace(',', '.')
				const ht_initial = elem_to_edit.avenantarticles_ht_initial.replace(',', '.').replace(/\s/g, '')

				const htunit_before_discount = (ht_initial * (part / 100))
				elem_to_edit.avenantarticles_htunit_before_discount = htunit_before_discount
				elem_to_edit.avenantarticles_ht_before_discount = (htunit_before_discount * qte)

				if(discount_type.invoicediscounttype_type == "numeraire") {
					elem_to_edit.avenantarticles_htunit = htunit_before_discount - discount_value
				}
				else if(discount_type.invoicediscounttype_type == "pourcentage" && discount_value > 0) {
					elem_to_edit.avenantarticles_htunit = htunit_before_discount - (htunit_before_discount * (discount_value / 100))
				}
				else {
					elem_to_edit.avenantarticles_htunit = htunit_before_discount
				}

				const ht = elem_to_edit.avenantarticles_htunit

				// ensuite on calcule le HT total et le TTC
				elem_to_edit.avenantarticles_ht = await this.priceFormat(ht * qte)
				elem_to_edit.avenantarticles_ttc = await this.priceFormat(ht * qte * (1 + elem_to_edit.vat_value))

				this.show_table = true
			},

			async setHtArticle(htunit, contractconfigconditionsarticles_id){
				this.show_ht = false
				let elem_to_edit = this.recalculated_articles.find(elem => contractconfigconditionsarticles_id == elem.contractconfigconditionsarticles_id)
				htunit = htunit.replace(",", ".").replace(/\s/g, '')
				elem_to_edit.articles_qte = elem_to_edit.articles_qte.toString().replace(",", ".").replace(/\s/g, '')

				const ht = htunit * elem_to_edit.articles_qte

				elem_to_edit.articles_ht = await this.priceFormat(ht)
				elem_to_edit.articles_ttc = await this.priceFormat(ht * (1 + elem_to_edit.vat.vat_value))
				elem_to_edit.articles_qte = await this.priceFormat(elem_to_edit.articles_qte)
				elem_to_edit.articles_htunit = await this.priceFormat(htunit)
				this.show_ht = true
			},
			async setQteArticle(qte, contractconfigconditionsarticles_id){
				this.show_ht = false
				let elem_to_edit = this.recalculated_articles.find(elem => contractconfigconditionsarticles_id == elem.contractconfigconditionsarticles_id)
				qte = qte.replace(",", ".").replace(/\s/g, '')
				elem_to_edit.articles_htunit = elem_to_edit.articles_htunit.toString().replace(",", ".").replace(/\s/g, '')
				
				const ht = qte * elem_to_edit.articles_htunit
				elem_to_edit.articles_ht = await this.priceFormat(ht)
				elem_to_edit.articles_ttc = await this.priceFormat(ht * (1 + elem_to_edit.vat.vat_value))
				elem_to_edit.articles_htunit = await this.priceFormat(elem_to_edit.articles_htunit)
				elem_to_edit.articles_qte = await this.priceFormat(qte)
				this.show_ht = true
			},

			async forceReloadCondition() {

				this.$refs.modalCalculTarif.show()
				this.modal_loaded = false
				this.ready = false
				this.recalculated_articles = []
				let recalculated_articles = await this.getContractConfigConditionByTypeMonte(this.contractconfig_id, this.typemonte.id)

				for (let index = 0; index < recalculated_articles.length; index++) {
					const conditions = recalculated_articles[index];
					for (let i = 0; i < conditions.conditions_articles.length; i++) {
						const articles = conditions.conditions_articles[i];
						articles.articles_label = articles.articles.articles_label
						articles.vat = articles.articles.vat
						articles.articles_ttc = articles.contractconfigconditionsarticles_ttc / 100
						articles.articles_htunit = articles.contractconfigconditionsarticles_htunit / 100
						articles.articles_ht = articles.contractconfigconditionsarticles_ht / 100
						articles.articles_qte = articles.contractconfigconditionsarticles_qte
						this.recalculated_articles.push(articles)
					}
				}
				this.modal_loaded = true
				this.ready = true
			},

			async reloadCondition(force_reload=false, recalculated_articles) {
				await this.queue.flush()
				return this.queue.enqueue(() => {
					return this._reloadCondition(force_reload, recalculated_articles)
				})
			},

			/*async _reloadCondition(force_reload=false)
			{
				this.horse_id = (this.avenant.avenant_horse != null) ? this.avenant.avenant_horse : this.created_horse_id


				this.table_busy = true
				let conditions_local = []

				if(this.typemonte != null && this.contractconfig_id != 0) {
					if(this.avenant.avenant_typemonte != this.typemonte.id) {
						force_reload = true
					}

					//Je vais load les articles si j'en ai, sinon je vais charger celles définit par le contract_config
					let conditions = this.articles
					if(this.articles.length == 0) {
						conditions = await this.getAvenantArticles(this.avenant.avenant_id)
					}

					if(this.reload_articles) {
                    	const contract = await this.getContract(this.contract_id)
                    	conditions = contract.articles
						this.reload_articles = false
					}

					if(!this.free && ((conditions.length == 0 && this.avenant.avenant_status != 4 ) || force_reload)) {
						let num = 1
						let conditions_new = await this.getContractConfigConditionByTypeMonte(this.contractconfig_id, this.typemonte.id)

						//Je vais chercher les tiers horse liés à la jument
						let tab_tiers = []
						let current_tiers = {
							tiers: {
								tiers_id: this.tiers.tiers_id,
								tiers_rs: this.tiers.tiers_rs,
							},
							pourcentage: 100
						}

						if(this.horse_id != null && this.horse_id != 0) {
							const pourcentage = await this.loadTiersCurrentPart(this.horse_id)

							if(pourcentage.tiers_horse.length > 0) {
								for (let i = 0; i < pourcentage.tiers_horse.length; i++) {
									tab_tiers.push({
										tiers: {
											tiers_id: pourcentage.tiers_horse[i].tiers.tiers_id,
											tiers_rs: pourcentage.tiers_horse[i].tiers.tiers_rs,
										},
										pourcentage: pourcentage.tiers_horse[i].tiers_horse_part.tiershorsepart_contract
									})
								}
							}
							else {
								tab_tiers.push(current_tiers)
							}
						}
						else {
							tab_tiers.push(current_tiers)
						}
						

						let tab_temp = []
						for (let i = 0; i < conditions_new.length; i++) {

							let label = ""
							let type = ""
							if(conditions_new[i].contract_conditions !== null && Object.keys(conditions_new[i].contract_conditions).length > 0) {
								label = this.getTrad(conditions_new[i].contract_conditions.contractconditions_label)
								type = conditions_new[i].contract_conditions.contractconditions_type
							}

							for (let j = 0; j < conditions_new[i].conditions_articles.length; j++) {

								let pourcentage_reste = 100
								
								for (let k = 0; k < tab_tiers.length; k++) {
									
									let ht = 0
									let ttc = 0

									if(tab_tiers.length == (k + 1)) {
										//Je suis au dernier je vais prendre le reste du pourcentage
										ht = conditions_new[i].conditions_articles[j].articles.articles_ht * (pourcentage_reste / 100 )
										ttc = conditions_new[i].conditions_articles[j].articles.articles_ttc * (pourcentage_reste / 100 )
									}
									else {
										ht = conditions_new[i].conditions_articles[j].articles.articles_ht * (tab_tiers[k].pourcentage / 100 )
										ttc = conditions_new[i].conditions_articles[j].articles.articles_ttc * (tab_tiers[k].pourcentage / 100 )
										pourcentage_reste -= (tab_tiers[k].pourcentage * 1)
									}

									tab_temp.push({
										contractconditions_label: label,
										contractconditions_type: type,
										contractconfigconditions_id: conditions_new[i].contractconfigconditions_id,
										avenantarticles_id: num * -1,
										articles_label: conditions_new[i].conditions_articles[j].articles.articles_label,
										articles_code: conditions_new[i].conditions_articles[j].articles.articles_code,
										articles_id: conditions_new[i].conditions_articles[j].articles.articles_id,
										initial_author: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.accountingplan_tiers,
										accountingaccount_id: conditions_new[i].conditions_articles[j].articles.accounting_account.accountingaccount_id,
										accountingaccount_fdvex_id: conditions_new[i].conditions_articles[j].articles.accounting_account_fdvex.accountingaccount_id,
										accountingaccount_fdvxp_id: conditions_new[i].conditions_articles[j].articles.accounting_account_fdvxp.accountingaccount_id,
										accountingaccount_vat_id: conditions_new[i].conditions_articles[j].articles.vat_account.accountingaccount_id,
										accountingaccount_vat_fdvex_id: conditions_new[i].conditions_articles[j].articles.vat_account_fdvex.accountingaccount_id,
										accountingaccount_vat_fdvxp_id: conditions_new[i].conditions_articles[j].articles.vat_account_fdvxp.accountingaccount_id,
										vat_value: conditions_new[i].conditions_articles[j].articles.vat.vat_value,
										avenantarticles_ht: await this.priceFormat(ht / 100),
										avenantarticles_ttc: await this.priceFormat(ttc / 100),
										avenantarticles_invoiceable: false,
										avenantarticles_invoiceable_text: this.getTrad("monte.facture_non"),
										disabled: false,
										avenantarticles_type_condition: null,
										button_type: 'delete',
										tiers: {
											tiers_id: tab_tiers[k].tiers.tiers_id,
											tiers_rs: tab_tiers[k].tiers.tiers_rs,
										},
										author: {
											tiers_id: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.tiers.tiers_id,
											tiers_rs: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.tiers.tiers_rs,
										}
									})
									num++
								}
							}
						}
						conditions_local = tab_temp
					}

					//J'affiche les conditions déja enregistrée sur l'avenant
					for (let i = 0; i < conditions.length; i++) {
						for (let j = 0; j < conditions[i].length; j++) {
							let label = ""
							let type = ""
		
							if(conditions[i][j].conditions_solo !== null && Object.keys(conditions[i][j].conditions_solo).length > 0) {
							 	label = this.getTrad(conditions[i][j].conditions_solo.contractconditions_label)
							 	type = conditions[i][j].conditions_solo.contractconditions_type
							}else if(conditions[i][j].conditions !== null && Object.keys(conditions[i][j].conditions).length > 0) {
								label = this.getTrad(conditions[i][j].conditions.contract_conditions.contractconditions_label)
								type = conditions[i][j].conditions.contract_conditions.contractconditions_type
							}
					
							if((this.free && conditions[i][j].avenantarticles_invoiceable) || !this.free) {
								conditions_local.push({
									contractconditions_label: label,
									contractconditions_type: type,
									contractconfigconditions_id: (conditions[i][j].conditions !== null && Object.keys(conditions[i][j].conditions).length > 0) ? conditions[i][j].conditions.contractconfigconditions_id : null,
									articles_label: conditions[i][j].article.articles_label,
									articles_code: conditions[i][j].article.articles_code,
									articles_id: conditions[i][j].article.articles_id,
									initial_author: conditions[i][j].article.accounting_account.accounting_plan.accountingplan_tiers,
									accountingaccount_id: conditions[i][j].article.accounting_account.accountingaccount_id,
									accountingaccount_fdvex_id: conditions[i][j].article.accounting_account_fdvex.accountingaccount_id,
									accountingaccount_fdvxp_id: conditions[i][j].article.accounting_account_fdvxp.accountingaccount_id,
									accountingaccount_vat_id: conditions[i][j].article.vat_account.accountingaccount_id,
									accountingaccount_vat_fdvex_id: conditions[i][j].article.vat_account_fdvex.accountingaccount_id,
									accountingaccount_vat_fdvxp_id: conditions[i][j].article.vat_account_fdvxp.accountingaccount_id,
									vat_value: conditions[i][j].article.vat.vat_value,
									avenantarticles_id: conditions[i][j].avenantarticles_id,
									avenantarticles_ht: await this.priceFormat(conditions[i][j].avenantarticles_ht / 100),
									avenantarticles_ttc: await this.priceFormat(conditions[i][j].avenantarticles_ttc / 100),
									avenantarticles_invoiceable_text: conditions[i][j].avenantarticles_invoiceable ? this.getTrad("monte.facture_oui") : this.getTrad("monte.facture_non"),
									button_type: conditions[i][j].avenantarticles_invoiceable ? 'credit' : 'delete',
									disabled: conditions[i][j].avenantarticles_invoiceable,
									avenantarticles_invoiceable: conditions[i][j].avenantarticles_invoiceable,
									avenantarticles_type_condition: (conditions[i][j].conditions_solo !== null && Object.keys(conditions[i][j].conditions_solo).length > 0) ? conditions[i][j].conditions_solo.contractconditions_id : null,
									tiers: {
										tiers_id: conditions[i][j].tiers.tiers_id,
										tiers_rs: conditions[i][j].tiers.tiers_rs,
									},
									author: {
										tiers_id: conditions[i][j].author.tiers_id,
										tiers_rs: conditions[i][j].author.tiers_rs,
									}
								})
							}
						}
					}
				}
				
				this.conditions_local = conditions_local
				this.table_busy = false
			},
			*/

			async _reloadCondition(force_reload, recalculated_articles){

				if(recalculated_articles){
					this.$refs.modalCalculTarif.hide()
				}

				this.table_busy = true
				this.horse_id = (this.avenant.avenant_horse != null) ? this.avenant.avenant_horse : this.created_horse_id

				this.table_busy = true
				let conditions_local = []
				if(this.typemonte != null && this.contractconfig_id != 0) {
					if(this.avenant.avenant_typemonte != this.typemonte.id) {
						force_reload = true
					}
				}

				//Si je viens de créer le contrat, ça sert à rien d'aller interroger le serveur car je l'ai pas envoyé
				if(this.avenant.avenant_id > 0) {
					conditions_local = await this.getAvenantArticlesOnline(this.avenant.avenant_id)
				}

				if(!this.free && ((conditions_local.length == 0 && this.avenant.avenant_status != 4 ) || force_reload)) {
					let num = 1
					let conditions_new = await this.getContractConfigConditionByTypeMonte(this.contractconfig_id, this.typemonte.id)

					//Je vais chercher les tiers horse liés à la jument
					let tab_tiers = []
					let current_tiers = {
						tiers: {
							tiers_id: this.tiers.tiers_id,
							tiers_rs: this.tiers.tiers_rs,
						},
						pourcentage: 100
					}

					if(this.horse_id != null && this.horse_id != 0) {
						const pourcentage = await this.loadTiersCurrentPart(this.horse_id)

						if(pourcentage.tiers_horse.length > 0) {
							for (let i = 0; i < pourcentage.tiers_horse.length; i++) {
								tab_tiers.push({
									tiers: {
										tiers_id: pourcentage.tiers_horse[i].tiers.tiers_id,
										tiers_rs: pourcentage.tiers_horse[i].tiers.tiers_rs,
									},
									pourcentage: pourcentage.tiers_horse[i].tiers_horse_part.tiershorsepart_contract
								})
							}
						}
						else {
							tab_tiers.push(current_tiers)
						}
					}
					else {
						tab_tiers.push(current_tiers)
					}
					

					let tab_temp = []
					for (let i = 0; i < conditions_new.length; i++) {

						let label = ""
						let type = ""
						if(conditions_new[i].contract_conditions !== null && Object.keys(conditions_new[i].contract_conditions).length > 0) {
							label = this.getTrad(conditions_new[i].contract_conditions.contractconditions_label)
							type = conditions_new[i].contract_conditions.contractconditions_type
						}

						for (let j = 0; j < conditions_new[i].conditions_articles.length; j++) {

							let pourcentage_reste = 100
							if (tab_tiers.length == 1){
								pourcentage_reste = tab_tiers[0].pourcentage
							}
							
							for (let k = 0; k < tab_tiers.length; k++) {
								
								let ht = 0
								let htunit = 0
								let ttc = 0
								let quote_part = 100
								let ht_initial = 0

								if(tab_tiers.length == (k + 1)) {
									//Je suis au dernier je vais prendre le reste du pourcentage
									if(recalculated_articles != undefined) {
										const article_recalculated = recalculated_articles.find(article => article.contractconfigconditionsarticles_id == conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_id)

										if(article_recalculated) {
											ht_initial = (article_recalculated.articles_htunit.toString().replace(",", ".").replace(/\s/g, '') * 100)
											htunit = (article_recalculated.articles_htunit.toString().replace(",", ".").replace(/\s/g, '') * 100) * (pourcentage_reste / 100 )
											ht = (article_recalculated.articles_ht.toString().replace(",", ".").replace(/\s/g, '') * 100) * (pourcentage_reste / 100 )
											ttc = (article_recalculated.articles_ttc.toString().replace(",", ".").replace(/\s/g, '') * 100) * (pourcentage_reste / 100 )
											conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_qte = article_recalculated.articles_qte
										}
										else {
											ht_initial = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit
											ht = conditions_new[i].conditions_articles[j].articles.articles_ht * (pourcentage_reste / 100 )
											htunit = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit * (pourcentage_reste / 100)
											ttc = conditions_new[i].conditions_articles[j].articles.articles_ttc * (pourcentage_reste / 100 )
										}
									}
									else {
										ht_initial = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit
										ht = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_ht * (pourcentage_reste / 100 )
										htunit = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit * (pourcentage_reste / 100)
										ttc = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_ttc* (pourcentage_reste / 100 )
									}
									
									quote_part = pourcentage_reste
								}
								else {
									if(recalculated_articles != undefined) {
										const article_recalculated = recalculated_articles.find(article => article.contractconfigconditionsarticles_id == conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_id)

										if(article_recalculated) {
											ht_initial = (article_recalculated.articles_htunit.toString().replace(",", ".").replace(/\s/g, '') * 100)
											htunit = (article_recalculated.articles_htunit.toString().replace(",", ".").replace(/\s/g, '') * 100) * (tab_tiers[k].pourcentage / 100 )
											ht = (article_recalculated.articles_ht.toString().replace(",", ".").replace(/\s/g, '') * 100) * (tab_tiers[k].pourcentage / 100 )
											ttc = (article_recalculated.articles_ttc.toString().replace(",", ".").replace(/\s/g, '') * 100) * (tab_tiers[k].pourcentage / 100 )
											conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_qte = article_recalculated.articles_qte
										}
										else {
											ht_initial = conditions_new[i].conditions_articles[j].articles.articles_htunit
											htunit = conditions_new[i].conditions_articles[j].articles.articles_htunit * (tab_tiers[k].pourcentage / 100 )
											ht = conditions_new[i].conditions_articles[j].articles.articles_ht * (tab_tiers[k].pourcentage / 100 )
											ttc = conditions_new[i].conditions_articles[j].articles.articles_ttc * (tab_tiers[k].pourcentage / 100 )
										}
									}
									else {
										ht_initial = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit
										ht = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_ht * (tab_tiers[k].pourcentage / 100 )
										htunit = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_htunit * (tab_tiers[k].pourcentage / 100)
										ttc = conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_ttc * (tab_tiers[k].pourcentage / 100 )
									}
									pourcentage_reste -= (tab_tiers[k].pourcentage * 1)
									quote_part = tab_tiers[k].pourcentage
								}

								conditions_local.push({
									contractconditions_label: label,
									contractconditions_type: type,
									contractconfigconditions_id: conditions_new[i].contractconfigconditions_id,
									avenantarticles_id: num * -1,
									articles_label: conditions_new[i].conditions_articles[j].articles.articles_label,
									articles_code: conditions_new[i].conditions_articles[j].articles.articles_code,
									articles_id: conditions_new[i].conditions_articles[j].articles.articles_id,
									initial_author: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.accountingplan_tiers,
									avenantarticles_comment: conditions_new[i].conditions_articles[j].avenantarticles_comment,
									accountingaccount_id: conditions_new[i].conditions_articles[j].articles.accounting_account.accountingaccount_id,
									accountingaccount_fdvex_id: conditions_new[i].conditions_articles[j].articles.accounting_account_fdvex.accountingaccount_id,
									accountingaccount_fdvxp_id: conditions_new[i].conditions_articles[j].articles.accounting_account_fdvxp.accountingaccount_id,
									accountingaccount_vat_id: conditions_new[i].conditions_articles[j].articles.vat_account.accountingaccount_id,
									accountingaccount_vat_fdvex_id: conditions_new[i].conditions_articles[j].articles.vat_account_fdvex.accountingaccount_id,
									accountingaccount_vat_fdvxp_id: conditions_new[i].conditions_articles[j].articles.vat_account_fdvxp.accountingaccount_id,
									vat_value: conditions_new[i].conditions_articles[j].articles.vat.vat_value,
									avenantarticles_quotepart: quote_part,
									avenantarticles_ht_initial: await this.priceFormat(ht_initial / 100),
									avenantarticles_qte: conditions_new[i].conditions_articles[j].contractconfigconditionsarticles_qte,
									avenantarticles_htunit: await this.priceFormat(htunit / 100),
									avenantarticles_htunit_before_discount: await this.priceFormat(htunit / 100),
									avenantarticles_ht_before_discount: await this.priceFormat(ht / 100),
									avenantarticles_ht: await this.priceFormat(ht / 100),
									avenantarticles_ttc: await this.priceFormat(ttc / 100),
									avenantarticles_discount_type: {invoicediscounttype_id: 0, invoicediscounttype_label: "-", invoicediscounttype_type: "none"},
									avenantarticles_discount_value: "0,00",
									avenantarticles_invoiceable: false,
									avenantarticles_invoiceable_text: this.getTrad("monte.facture_non"),
									disabled: false,
									avenantarticles_type_condition: null,
									button_type: 'delete',
									tiers: {
										tiers_id: tab_tiers[k].tiers.tiers_id,
										tiers_rs: tab_tiers[k].tiers.tiers_rs,
									},
									author: {
										tiers_id: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.tiers.tiers_id,
										tiers_rs: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.tiers.tiers_rs,
										tiers_currency: conditions_new[i].conditions_articles[j].articles.accounting_account.accounting_plan.tiers.tiers_currency,
									}
								})
								num++
							}
						}
					}
				}

				this.conditions_local = conditions_local
				this.table_busy = false
			},

			async create_credit(val){
				const elem = this.conditions_local.find(elem => elem.avenantarticles_id == val)
				const cloneElem = Object.assign({}, elem);

				cloneElem.avenantarticles_invoiceable = false
				cloneElem.avenantarticles_invoiceable_text = this.getTrad("monte.facture_non")
				cloneElem.disabled = false
				cloneElem.avenantarticles_htunit_before_discount = await Common.priceFormat(Common.cleanValue(cloneElem.avenantarticles_htunit_before_discount) * -1)
				cloneElem.avenantarticles_ht_before_discount = await Common.priceFormat(Common.cleanValue(cloneElem.avenantarticles_ht_before_discount) * -1)
				cloneElem.avenantarticles_htunit = await Common.priceFormat(Common.cleanValue(cloneElem.avenantarticles_htunit) * -1)
				cloneElem.avenantarticles_ht = await Common.priceFormat(Common.cleanValue(cloneElem.avenantarticles_ht) * -1)
				cloneElem.avenantarticles_qte = Common.cleanValue(cloneElem.avenantarticles_qte)
				cloneElem.avenantarticles_ttc = await Common.priceFormat(Common.cleanValue(cloneElem.avenantarticles_ttc) * -1)
				cloneElem.avenantarticles_id = Common.getNegativeId()
				cloneElem.button_type = 'delete'

				this.conditions_local.push(cloneElem)
			},

			delete_ligne(val){
				const index = this.conditions_local.findIndex(elem => elem.avenantarticles_id == val)
				this.conditions_local.splice(index, 1)
			},

			resetConditions(){
				this.conditions_local = []
			},

			addArticlesToAvenant(){
				this.$refs.modalLigneVierge.show()
				this.modal_loaded = true
			},
			async onSubmit(){
				this.processing = true
				let invoice_details = await this.$refs.ligne.submitForm()

				if(invoice_details) {
					this.$refs["modalLigneVierge"].hide()
					this.reload_articles = true
					this.successToast()
					this.reloadCondition()
				}

				this.processing = false
				this.ready = true
			},

			reloadArticleEvent() {
				this.reload_articles = true
				this.reloadCondition()
			}
		},
		computed: {
			conditions_formatted(){
				let tab = []

				for (let index = 0; index < this.conditions_local.length; index++) {
					const element = this.conditions_local[index];
					element.avenantarticles_invoiceable_text = this.getTrad(element.avenantarticles_invoiceable_text)
					element.button_type = this.getTrad(element.button_type)
					element.contractconditions_label = this.getTrad(element.contractconditions_label)

					if(element.avenantarticles_discount_type == null) {
						element.avenantarticles_discount_type = {invoicediscounttype_id: 0, invoicediscounttype_label: "-", invoicediscounttype_type: "none"}
					}

					tab.push(element)
				}

				return tab
			},
			discount_type_formatted(){
				let tab = []

				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				return tab
			}
		},
		watch: {
			conditions_local: {
				deep: true,
				handler(val) {
					this.$emit("update:avenant_article", val)
				}
			},

			typemonte(){
				this.conditions_local = []
				this.reloadCondition()
			},

			free(){
				this.reloadCondition()
			},

			change_emetteur(val){
				for (let i = 0; i < this.conditions_local.length; i++) {
					if(this.conditions_local[i].contractconditions_type !== "frais_technique") {
						this.conditions_local[i].author = val
					}
				}

				const conditions_local_copy = _cloneDeep(this.conditions_local)
				this.$set(this.conditions_local, conditions_local_copy)
			},

			table_busy(val) {
				this.$emit('update:is_ready', !val)
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
			ModalAddArticle: () => import('@/components/Contract/ModalAddArticle'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput')
		}
	}

</script>

<style scoped>
	.no-color-no-background
	{
		color: #DC1258;
		background-color: inherit;
		border: none; 
	}
</style>